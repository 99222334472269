import { EventScreen } from '@gf/cross-platform-lib/modules';
import { getEventSeasonServerSideProps, IWebServerProps } from '@gf/cross-platform-lib';
import { NextApiResponse } from 'next';

export default EventScreen;

export async function getServerSideProps({
  res,
  query
}: {
  res: NextApiResponse;
  query: { id: string };
}): Promise<IWebServerProps> {
  try {
    const { id: eventId } = query;
    const rs = await getEventSeasonServerSideProps(eventId);
    res.setHeader('Cache-Control', 'public, s-maxage=20, stale-while-revalidate=59');
    return rs;
  } catch (e) {
    console.error(e);
    return {
      props: {}
    };
  }
}
