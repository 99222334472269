import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styled from '@gf/cross-platform-lib/styled-components';
import get from 'lodash/get';
import { LayoutContent } from '../app/Layout';
import { useParams, useNavigate, useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { Cart } from '@gf/cross-platform-lib/models';
import { ProductSeating, ProductType } from '@gf/cross-platform-lib/interfaces';
import { EventDetailsView } from './views';
import { GoToTopButton } from '@gf/cross-platform-lib/components/GoToTopButton';
import { AccessCodeProvider, GfSkeletonProvider, ScrollViewProvider } from '@gf/cross-platform-lib/providers';
import { doesExist, isEventActivityFundraiser, eventDetailsPage, testProperties } from '@gf/cross-platform-lib/utils';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { useFetchEventSponsorQuery, useGetEventQuery } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { View } from 'react-native';
import { LoadingSpinner } from '@gf/cross-platform-lib/components';
import { EventDetailSkeleton } from './components';
import { useGetEventDetailFeatureFlags } from '../../hooks/useGetEventDetailFeatureFlags';

interface EventScreenProps {
  ticketEncodedStrings?: string[];
  id?: string;
  schoolId?: string;
  uniqueProductId?: string;
  encodedString?: string;
  hiddenTickets?: ProductSeating[];
  isHiddenTicket?: boolean;
}

interface EventScreenQuery {
  id: string;
  schoolId?: string;
  uniqueProductId?: string;
}

export const EventScreen = (props: EventScreenProps) => {
  const params = useParams() as EventScreenQuery;
  const eventId = params?.id || props.id;
  const schoolId = params?.schoolId || props.schoolId;
  const { ticketEncodedStrings } = props;
  const { isMobile } = useMediaQuery();

  const eventResponse = useGetEventQuery(eventId!);
  const event = eventResponse.data?.data;

  const isDonationOngoing = event?.ticketTypes.every(ticket => ticket.productType === ProductType.DONATION_ONGOING);
  const isMobilePass = event?.ticketTypes.every(ticket => ticket.productType === ProductType.MOBILEPASS);

  const sponsor = useFetchEventSponsorQuery({
    eventIds: [event?.id || ''],
    enabled: !isDonationOngoing && !isMobilePass
  }).data?.data;
  const { isEventContext, isTargetedUser } = useGetEventDetailFeatureFlags({ eventId, schoolId });

  const [pullDownLoading, setPullDownLoading] = useState(false);

  if (event && props.hiddenTickets && props.hiddenTickets.length > 0) {
    event.ticketTypes = event.ticketTypes.map(tkType => {
      const hiddenTicketType = (props.hiddenTickets || []).find(hiddenTicket => hiddenTicket.id === tkType.id);
      return {
        ...tkType,
        encodedString: hiddenTicketType?.encodedString,
        isReservedSeating: hiddenTicketType?.reservedSeating,
        isPromotionRequired: hiddenTicketType?.promotionRequired,
        form: hiddenTicketType?._embedded?.form
      };
    });
  }
  const isFundraiser = useMemo(() => isEventActivityFundraiser(event), [event]);

  const loading =
    eventResponse.isLoading ||
    // Assume a loading state if eventId param does not match stored event.id
    (!!event && String(event.id) !== String(eventId));
  let errorCode =
    get(eventResponse, 'error.httpStatus.code') ||
    // Treat missing eventId as 404
    (!doesExist(eventId) && !props.isHiddenTicket)
      ? 404
      : null;

  const cartInstance = Cart.getInstance();
  const navigate = useNavigate();

  useEffect(() => {
    cartInstance.init();
  }, []);

  useEffect(() => {
    const canceled = event && event.canceled;
    const endDate = event && event.endDateTime ? dayjs(event.endDateTime) : null;
    const expiredDate = endDate?.add(30, 'day');
    if (canceled || (expiredDate && expiredDate.isBefore(dayjs()))) {
      const schoolPath = GOFAN_APP_PAGES.school.getPath(event?.schoolHuddleId);
      navigate(schoolPath);
    }
  }, [event]);

  useEffect(() => {
    if (errorCode) {
      navigate(`/error?code=${errorCode}`);
    }
  }, [errorCode]);

  return (
    <GfSkeletonProvider show={loading} skeletonComponent={<EventDetailSkeleton isMobile={isMobile} />}>
      <LayoutContent>
        {pullDownLoading && (
          <View style={{ alignItems: 'center' }}>
            <LoadingSpinner spinnerSize={40} asSpinner usingLoadingIndicator={false}></LoadingSpinner>
          </View>
        )}
        {eventId && event ? (
          <ScrollViewProvider>
            <AccessCodeProvider currentRoute={GOFAN_APP_PAGES.event.path}>
              <EventDetailsView
                key={event.id}
                eventDetail={event}
                isSeason={false}
                ticketEncodedStrings={ticketEncodedStrings}
                schoolId={schoolId ?? event.schoolHuddleId}
                encodedString={props.encodedString ?? props.encodedString}
                isFundraiser={isFundraiser}
                isDonationOngoing={isDonationOngoing}
                isMobilePass={isMobilePass}
                isFetchingEvent={eventResponse.isFetching}
                refetchEvent={eventResponse.refetch}
                setPullDownLoading={setPullDownLoading}
                pullDownLoading={pullDownLoading}
                sponsor={sponsor}
                isEventContext={isEventContext}
                isTargetedUser={isTargetedUser}
              />
              {!isFundraiser && (
                <ButtonWrapper {...testProperties(eventDetailsPage.goToTop)}>
                  <GoToTopButton />
                </ButtonWrapper>
              )}
            </AccessCodeProvider>
          </ScrollViewProvider>
        ) : null}
      </LayoutContent>
    </GfSkeletonProvider>
  );
};

const ButtonWrapper = styled.View`
  margin-bottom: 32px;
`;
